import { useMemo } from 'preact/compat';
import useAccountProgressState from '../../../../hooks/use-account-progress-state';
export default function usePromotionAdTypes(existingTradingCreditPromotion) {
    const accountProgressState = useAccountProgressState();
    return useMemo(()=>{
        if (accountProgressState === 'NO_ACCOUNT') {
            return [
                'NEW_ACCOUNT'
            ];
        }
        if (accountProgressState === 'NO_BANK_ACCOUNT') {
            return [
                'DEPOSIT'
            ];
        }
        if (accountProgressState === 'UNFINISHED_REGISTRATION') {
            return [
                'UNFINISHED_REGISTRATION'
            ];
        }
        const types = [
            'TRADING_CREDIT'
        ];
        if (accountProgressState === 'NO_TRADE') {
            types.unshift('FIRST_TRADE');
        }
        if (existingTradingCreditPromotion) {
            types.push('ACTIVE_TRADING_CREDIT');
        }
        return types;
    }, [
        accountProgressState,
        existingTradingCreditPromotion
    ]);
}
